// const config = {
//   token: {
//     colorPrimary: '#00b14f',
//     colorPrimaryBg: '#00b14f',
//     // bgHeader: 2,
//     colorText: '#363a45',
//     // lineHeight: '28'
//   },
//   components: {
//     Layout: {
//       headerBg: '#fff',
//       siderBg: '#FFF',
//       // triggerColor: '#e6f7ed'
//     },
//     Menu: {
//       itemHeight: '40px',
//       itemActiveBg: '#e6f7ed',
//       itemSelectedBg: '#EEF9F9',
//       itemSelectedColor: '#005339',
//       horizontalItemSelectedColor: '#005339',
//     },
//     Typography: {
//       titleMarginBottom: '1em',
//     },
//   },
// };

const config = {
  token: {
    // Alias Token
    colorBgContainer: '#fff',
  },
  components: {
    Layout: {
      headerBg: '#fff',
      siderBg: '#FFF',
      bodyBg: '#fff',
    },
    Table: {
      footerBg: '#fff',
    },
    Collapse: {
      colorBorder: '#d9d9d9',
    },
    // Tabs: {
    //   pillLineHeightSM: 1,
    // },
  },
};

export default config;
