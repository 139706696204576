import React from 'react';
import { Button } from '@duxton-web/ui';

const ErrorFallback = ({ error = '', title = '', action = undefined }) => {
  return (
    <React.Fragment>
      <div>
        {process.env.NODE_ENV === 'development' ? (
          <React.Fragment>
            <h3>stack</h3>
            <p>{error.stack}</p>
          </React.Fragment>
        ) : (
          <h2>Web Code Error!</h2>
        )}
      </div>
      <Button
        onClick={() => (action.onClick ? action.onClick() : location.reload())}
      >
        {action.text || 'Reload'}
      </Button>
    </React.Fragment>
  );
};

export { ErrorFallback };
