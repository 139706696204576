/* eslint-disable camelcase */
import { Component } from 'react';
import PropTypes from 'prop-types';

export default class Bundle extends Component {
  static defaultProps = {
    children: () => {},
    load: () => {},
  };

  static propTypes = {
    children: PropTypes.func,
    load: PropTypes.func,
  };

  state = {
    mod: null,
  };

  UNSAFE_componentWillMount() {
    this.load(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.load !== this.props.load) {
      this.load(nextProps);
    }
  }

  load(props) {
    this.setState({
      mod: null,
    });

    props.load().then((mod) => {
      this.setState({
        mod: mod.default ? mod.default : mod,
      });
    });
  }

  render() {
    return this.props.children(this.state.mod);
  }
}
