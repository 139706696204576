import React, { Suspense } from 'react';
import { Spin } from '@duxton-web/ui';
import { ErrorFallback } from './ErrorFallback';
import { ErrorBoundary } from 'react-error-boundary';

const LazyWithErrorBoundary = (component) => (
  <Suspense fallback={<Spin />}>
    <ErrorBoundary
      FallbackComponent={({ error }) => (
        <ErrorFallback
          error={error}
          title="Something went wrong"
          desc="Try reload"
          action={{ text: 'reload', onClick: () => location.reload(true) }}
        />
      )}
    >
      {component}
    </ErrorBoundary>
  </Suspense>
);

export default LazyWithErrorBoundary;
