import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import FastClick from 'fastclick';
import I18n from 'i18n-js';
import Raven from 'raven-js';
import { getLocaleFromBrowserLanguage } from 'grab-locale';
import Storage from 'grab-storage';
// import 'grab-ui/dist/theme.less';
// import 'antd/dist/antd.less';
import '@duxton-web/inter';
import App from './pages/app/App';
import './index.less';

window.Grab = {
  config: {},
  router: {},
  storage: Storage,
};

FastClick.attach(document.body);

const LOCALES = ['en', 'id', 'ms', 'th', 'tl', 'vi'];
const locale = getLocaleFromBrowserLanguage({
  defaultLocale: 'en',
  availableLocales: LOCALES,
  navigator: window.navigator,
});

const renderApp = () => {
  render(
    // <BrowserRouter>
    //   <App />
    // </BrowserRouter>,
    <App />,
    document.getElementById('root')
  );
};

window
  .fetch(require('config.json'))
  .then(async (configResponse) => {
    if (configResponse.status !== 200) return;
    window.Grab.config = await configResponse.json();
    window
      .fetch(require(`public/locales/${locale}.json`))
      .then(async (localeResponse) => {
        if (localeResponse.status !== 200) return;
        I18n.translations[locale] = await localeResponse.json();
        I18n.locale = locale;

        if (window.Grab.config.sentryDsn) {
          Raven.config(window.Grab.config.sentryDsn, {
            release: `starter-rest@${process.env.APP_VERSION}`,
          }).install();
        }

        renderApp();
      })
      .catch((err) => {
        Raven.captureException(err);
        console.error(err);
      });
  })
  .catch((err) => {
    Raven.captureException(err);
    console.error(err);
  });

if (module.hot) {
  module.hot.accept('./pages/app/App', () => {
    renderApp();
  });
}
