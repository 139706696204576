import React, { useState, useMemo, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Layout,
  Menu,
  Avatar,
  Button,
  Space,
  Popover,
  Typography,
  Row,
  Flex,
} from '@duxton-web/ui';
import { MoreVerticalUniversal, LogoutUniversal } from '@duxton-web/icons';
import { getCurrentUser, logout } from 'grab-login/lib/helper';
import classnames from 'classnames';
import styles from './AppSider.less';
import { ROUTES } from './Routes';

const { Text } = Typography;
const { Sider } = Layout;

const Sidebar = (props) => {
  const location = useLocation();
  const navigate = useNavigate();
  const currentUser = getCurrentUser();
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys, setOpenKeys] = useState([]);
  const getRouteKey = (pathname) => {
    if (!pathname) {
      return ['/'];
    }

    const matched = pathname.split('/')?.[1];
    return matched ? [`/${matched}/:id`, `/${matched}`] : [`/`];
  };

  const selectedKeys = useMemo(
    () => getRouteKey(location.pathname),
    [location]
  );

  useEffect(() => {
    const regex = /(?<=\/).*?(?=\/)/g;
    const matches = location.pathname.match(regex);
    const locationOpenKeys = matches || [];
    setOpenKeys(locationOpenKeys);
  }, [location]);

  const handleClick = (e) => {
    navigate(e.key);
  };

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
  };

  const handleLogout = () => {
    logout();
    navigate('/login', { replace: true });
  };
  return (
    <Sider
      width={280}
      collapsedWidth={76}
      className={classnames(styles.sider)}
      onCollapse={(value) => setCollapsed(value)}
    >
      <Flex vertical justify="space-between" style={{ height: '100%' }}>
        <Link className={classnames(styles.logoWrapper)} to={'/'}>
          <img
            className={classnames(styles.logo)}
            alt={'Grab'}
            src={require('public/images/brand.svg')}
          />
        </Link>
        <div className={classnames(styles.content)}>
          <div className={classnames(styles.topContent)}>
            <Menu
              mode="inline"
              defaultOpenKeys={[]}
              key={ROUTES.length}
              openKeys={openKeys}
              onOpenChange={onOpenChange}
              selectedKeys={selectedKeys}
              onClick={handleClick}
              className={`
                ${styles.sider__menu} ${
                collapsed ? styles.sider__menu__triggered : ''
              }
              `}
              items={ROUTES.map((route) => {
                if (route.children) {
                  return {
                    children: route.children.map(
                      (child) =>
                        !child.hide && {
                          key: child.key,
                          label: child.label,
                          icon: child.icon,
                        }
                    ),
                    icon: route.icon,
                    key: route.key,
                    label: route.label,
                  };
                }
                return (
                  !route.hide && {
                    key: route.key,
                    label: route.label,
                    icon: route.icon,
                  }
                );
              })}
            ></Menu>
          </div>
        </div>

        <Flex justify="space-around" className={classnames(styles.siderAvater)}>
          <Flex align="center">
            <Space>
              <Avatar size="small">{'user'}</Avatar>
              <Flex vertical justify="space-between" align="flex-start">
                <Text>{currentUser?.name?.split('@')?.[0] || '-'}</Text>
                <Text type="secondary" style={{ color: '#bfbfbf' }}>
                  {currentUser?.name || '-'}
                </Text>
              </Flex>
            </Space>
          </Flex>

          <Popover
            placement="top"
            title={''}
            trigger="click"
            arrow={false}
            content={
              <Button
                type="text"
                size="small"
                icon={<LogoutUniversal />}
                onClick={handleLogout}
              >
                Logout
              </Button>
            }
          >
            <MoreVerticalUniversal />
          </Popover>
        </Flex>
      </Flex>
    </Sider>
  );
};

export default Sidebar;
