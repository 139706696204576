import React, { Component } from 'react';
import { Spin } from '@duxton-web/ui';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './Spinner.less';

export default class Spinner extends Component {
  static defaultProps = {
    size: 'default',
  };

  static propTypes = {
    size: PropTypes.string,
  };

  state = {};

  render() {
    const { size } = this.props;

    return (
      <div className={classnames(styles.wrapper)}>
        <Spin size={size} />
      </div>
    );
  }
}
