import jwtDecode from 'jwt-decode';
// import Raven from 'raven-js';

export const TOKEN_NAME = '__TOKEN__';

export function getToken() {
  return window.localStorage.getItem(TOKEN_NAME);
}

export function getCurrentUser() {
  let currentUser = null;
  const token = getToken();

  if (!token) {
    return null;
  }

  try {
    const decodedToken = jwtDecode(token);
    currentUser = {
      name: decodedToken.name,
    };
  } catch (err) {
    // Raven.captureException(err);
  }

  return currentUser;
}

export function login(token) {
  window.localStorage.setItem(TOKEN_NAME, token);
  // Raven.setUserContext(getCurrentUser());
}

export function logout() {
  // Raven.setUserContext();
  return window.localStorage.removeItem(TOKEN_NAME);
}

export function isAuthenticated() {
  const token = getToken();

  if (!token) {
    return false;
  }

  try {
    const decodedToken = jwtDecode(token);

    if (!decodedToken.exp || decodedToken.exp < Date.now() / 1000) {
      logout();
      return false;
    }
  } catch (err) {
    // Raven.captureException(err);
    logout();
    return false;
  }

  return true;
}

export function getSecondsToExpiry() {
  const token = getToken();

  if (!token) {
    return 0;
  }

  try {
    const decodedToken = jwtDecode(token);
    return decodedToken.exp - Math.floor(Date.now() / 1000);
  } catch (err) {
    // Raven.captureException(err);
  }
  return 0;
}
